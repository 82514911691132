import { template as template_bfc9ca147aa44b0085af45f64dce0e36 } from "@ember/template-compiler";
const WelcomeHeader = template_bfc9ca147aa44b0085af45f64dce0e36(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
