import { template as template_2aa2339839cf4b99a672e349ff1a5b43 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_2aa2339839cf4b99a672e349ff1a5b43(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
